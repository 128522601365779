<template>
  <div class="datewrapopen" @click="dateinfo=!dateinfo">
<!--    <a>1주일 • 최신순</a>-->
    <a>
      <template v-if="day === -1">{{$t('front.common.yesterday')}}</template>
      <!--template v-if="day === 0">{{$t('front.common.today')}}</template-->
      <template v-if="day === 7">{{$t('front.common.aWeek')}}</template>
      <template v-if="day === 15">{{$t('front.common.15day')}}</template>
      <template v-if="day === 30">{{$t('front.common.1month')}}</template>
    </a>
    <span>• {{$t('front.give.detailSearch')}}</span>
  </div>
  <div class="datewrap" v-if="dateinfo">
     <ul>
       <li class="datebtns">
         <!--a :class="{'on': day === 0}" @click="getDate(0)">{{$t('front.common.today')}}</a-->
         <a :class="{'on': day === 7}" @click="getDate(7)">{{$t('front.common.aWeek')}}</a>
         <a :class="{'on': day === 15}" @click="getDate(15)">{{$t('front.common.15day')}}</a>
         <a :class="{'on': day === 30}" @click="getDate(30)">{{$t('front.common.1month')}}</a>
         <!--a v-if="add === '3month'" class="tmonth" @click="getDate(90)">3개월</a-->
       </li>
       <li class="dateperiod">
         <input type="date" v-model="model.startDate" @change="onChangeStartDate"/>
         <span>~</span>
         <input type="date" v-model="model.endDate" @change="onChangeEndDate"/>
       </li>
       <li class="before">
          <input id="before" type="checkbox" />
          <label for="before"></label>
          <span>이전데이터 검색</span>
       </li>
   <!--    <li class="datebtns">-->
   <!--      <a class="on">최신순</a>-->
   <!--      <a class="">과거순</a>-->
   <!--    </li>-->
       <li>
         <a v-if="add != '3month' || !retail" class="search" @click="onSearch()">{{$t('front.board.look')}}</a>
       </li>
       <!--li v-if="add === '3month' || retail">
         <input class="ml5" type="text" :placeholder="$t('front.common.memId')" v-if="retail" v-model="memId">
         <a class="search" @click="onSearch()">{{$t('front.give.search')}}</a>
       </li-->
     </ul>
  </div>
</template>

<script>
import DateFilter from '@/components/ui/DateFilter'

export default {
  name: 'DateFilterMobile',
  data () {
    return {
      dateinfo: false
    }
  },
  mixins: [DateFilter],
  props: [
    'add'
  ]

}
</script>
<style scoped src="@/styles_mobile/subcommon.css"></style>
